<template>
  <div class="page-teacher-mine">
    <div class="my-page">
      <div class="my-card">
        <van-cell-group>
          <van-cell title="头像" style="align-items: center">
            <!-- 使用 right-icon 插槽来自定义右侧图标 -->
            <template #right-icon>
              <img
                class="pic"
                :src="
                  viewData.ProfilePicture ? viewData.ProfilePicture : defaultImg
                "
                alt=""
                @click.stop="openUpload"
                @error="errorImg"
              />
              <van-uploader
                v-model="accessoryList"
                :deletable="true"
                :preview-full-image="false"
                accept="image/*"
                :before-read="beforeRead"
                @click-preview="review"
                style="width: 0; height: 0"
              >
                <span id="upload_span"></span>
              </van-uploader>
              <van-icon
                name="arrow"
                color="#969799"
                size="0.42667rem"
                style="margin-left: 0.10667rem"
              />
            </template>
          </van-cell>
          <van-cell title="姓名" :value="viewData.RealName" is-link />
          <van-cell title="手机号" :value="viewData.CellPhone" is-link />
          <van-cell
            title="家长信箱"
            :value="'最新' + mailbox + '条'"
            is-link
            @click="viewMailbox"
          />
        </van-cell-group>
      </div>
      <van-button type="primary" block color="#47AFA7" @click="change"
        >切换角色</van-button
      >
    </div>
    <!-- 菜单栏 -->
    <NavBar v-bind:active="3" />
  </div>
</template>

<script>
import defaultImg from "@/assets/icon/icon-my.png";
import NavBar from "@/components/NavBar";
import { Button, Cell, CellGroup, Icon, Uploader, Toast } from "vant";
export default {
  components: {
    NavBar,
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Icon.name]: Icon,
    [Uploader.name]: Uploader,
    [Toast.name]: Toast,
  },
  data() {
    return {
      defaultImg: defaultImg,
      userType: window.localStorage.getItem("UserType"),
      userInfo: JSON.parse(window.localStorage.getItem("userInfo")),
      accessoryList: [],
      viewData: {},
      mailbox: 0,
    };
  },
  mounted() {
    // 加载头像
    // 获取登录人信息
    this.getInfo();
    this.getMailbox();
  },
  methods: {
    getMailbox() {
      this.$axios.get("/api/ParentMailbox/GetPsersonal").then((res) => {
        this.mailbox = res.data.count;
      });
    },
    viewMailbox() {
      this.$router.push("MailboxForTeacherList");
    },
    getInfo() {
      this.$axios
        .get("/api/User/Get", {
          id: this.userInfo.ID,
        })
        .then((res) => {
          this.viewData = res.data;
        });
    },
    change() {
      const obj = {
        OpenID: this.userInfo.OpenID,
        headUrl: this.userInfo.HeadUrl,
      };
      window.localStorage.setItem("wxuserInfo", JSON.stringify(obj));
      this.$router.replace("ChangeRole");
    },
    errorImg() {
      const img = event.srcElement;
      img.src = this.defaultImg;
      img.onerror = null;
    },
    beforeRead(file) {
      if (file.size / 1024 > 1025) {
        this.$compress.photoCompress(
          file,
          {
            quality: 0.2,
          },
          (base64Codes) => {
            file = this.$compress.dataURLtoFile(base64Codes, file.name);
            Toast.loading({
              message: "加载中...",
              duration: 0,
            });
            let formData = new FormData();
            formData.append("LastFile", file);
            formData.append("FileName", file.name);
            this.$axios.upload("/api/File/UploadFile", formData).then((res) => {
              Toast.clear();
              if (res.code === 200) {
                // this.accessoryList.push({
                //   name: file.name,
                //   osskey: res.data.osskey,
                //   uploadUrl: res.data.uploadUrl,
                //   url: res.data.url
                // })

                // 提交头像
                this.editPic(res.data);
                return true;
              } else {
                this.$toast.fail(res.msg || "操作失败");
                return false;
              }
            });
          }
        );
      } else {
        Toast.loading({
          message: "加载中...",
          duration: 0,
        });
        let formData = new FormData();
        formData.append("LastFile", file);
        formData.append("FileName", file.name);
        this.$axios.upload("/api/File/UploadFile", formData).then((res) => {
          Toast.clear();
          if (res.code === 200) {
            // this.accessoryList.push({
            //   name: file.name,
            //   osskey: res.data.osskey,
            //   uploadUrl: res.data.uploadUrl,
            //   url: res.data.url
            // })

            // 提交头像
            this.editPic(res.data);
            return true;
          } else {
            this.$toast.fail(res.msg || "操作失败");
            return false;
          }
        });
      }
    },
    review(file) {
      if (file.url) {
        window.open(file.url);
      }
    },
    editPic(params) {
      this.$axios
        .post("/api/User/EditUserUrlForApp", {
          UserUrlList: [params],
          ID: this.userInfo.ID,
        })
        .then((res) => {
          this.$toast.success(res.msg || "操作成功");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    },
    openUpload() {
      this.$jq("#upload_span").siblings("input").click();
    },
    ceshi(){
      this.$router.replace("ceshi");
    }
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
